<template>
  <div class="filter">
    <div class="search-header">
      <div>
        <i @click="$router.back(-1)" class="iconfont icon-arrow02"></i>
      </div>
      <div>
        <van-search @click="showSearch = true" placeholder="搜教室、楼层" />
      </div>
    </div>
    <div class="swiper-toggle-page">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
        @ready="handleSwiperReadied"
        @click-slide="handleClickSlide"
        :autoUpdate="true"
      >
        <swiper-slide v-for="(item, i) in dataArr" :key="i">
          <div
            class="toggle-equipment"
            :class="currentIndex === i ? 'active' : ''"
            @click="toggleEquipment(i)"
          >
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="list">
      <van-tabs v-model="type">
        <van-tab title="全部">
          <van-list finished-text="没有更多了">
            <div
              @click="$router.push({ path: '/SmartClassroom/AlarmDetails' })"
              class="e-list"
              v-for="(item, index) in list"
              :key="index"
            >
              <div>
                <div>
                  <van-image
                    width="55px"
                    height="55px"
                    fit="contain"
                    :src="item.img"
                  ></van-image>
                </div>
                <div>
                  <div>{{ item.title }}</div>
                  <div>{{ item.local }}</div>
                </div>
              </div>
              <div>
                <div>{{ item.time }}</div>
                <div
                  :style="{
                    color: item.state
                      ? defineStyle.color.green
                      : defineStyle.color.red,
                  }"
                >
                  {{ item.state ? "已处理" : "未处理" }}
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="已处理">
          <van-list finished-text="没有更多了">
            <div v-for="(item, index) in list" :key="index">
              <div
                @click="$router.push({ path: '/SmartClassroom/AlarmDetails' })"
                class="e-list"
                v-if="item.state === 1"
              >
                <div>
                  <div>
                    <van-image
                      width="55px"
                      height="55px"
                      fit="contain"
                      :src="item.img"
                    ></van-image>
                  </div>
                  <div>
                    <div>{{ item.title }}</div>
                    <div>{{ item.local }}</div>
                  </div>
                </div>
                <div>
                  <div>{{ item.time }}</div>
                  <div
                    :style="{
                      color: item.state
                        ? defineStyle.color.green
                        : defineStyle.color.red,
                    }"
                  >
                    {{ item.state ? "已处理" : "未处理" }}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="未处理">
          <van-list finished-text="没有更多了">
            <div v-for="(item, index) in list" :key="index">
              <div
                @click="$router.push({ path: '/SmartClassroom/AlarmDetails' })"
                class="e-list"
                v-if="item.state === 0"
              >
                <div>
                  <div>
                    <van-image
                      width="46px"
                      height="40px"
                      fit="contain"
                      :src="item.img"
                    ></van-image>
                  </div>
                  <div>
                    <div>{{ item.title }}</div>
                    <div>{{ item.local }}</div>
                  </div>
                </div>
                <div>
                  <div>{{ item.time }}</div>
                  <div
                    :style="{
                      color: item.state
                        ? defineStyle.color.green
                        : defineStyle.color.red,
                    }"
                  >
                    {{ item.state ? "已处理" : "未处理" }}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>

      <!-- <van-grid :column-num="2" :gutter="16">
        <van-grid-item v-for="(item, index) in list" :key="index">
          <div class="list-item">
            <div
              @click="
                $router.push({
                  path: item.url,
                  query: {
                    active: $route.query.active ? $route.query.active : 0,
                  },
                })
              "
            >
              {{ item.title }}
            </div>
            <div
              @click="
                $router.push({
                  path: item.url,
                  query: {
                    active: $route.query.active ? $route.query.active : 0,
                  },
                })
              "
            >
              {{ item.local }}
            </div>
          </div>
        </van-grid-item>
      </van-grid> -->
    </div>
    <van-popup v-model="showSearch" position="bottom" style="height: 100%">
      <search-block
        :loading="loading"
        :finished="finished"
        :placeholder="placeholder"
        @CancelSearch="showSearch = false"
        @SearchLoad="onLoad"
        @OnSearch="onSearch"
        :dataList="dataList"
        @clickSingle="setData"
      ></search-block>
    </van-popup>
  </div>
</template>
<script>
import SearchBlock from "@/components/SearchBlock.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import {
  Search,
  Grid,
  GridItem,
  Popup,
  Tab,
  Tabs,
  List,
  Image as VanImage,
} from "vant";
export default {
  components: {
    Swiper,
    SwiperSlide,
    [Search.name]: Search,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [VanImage.name]: VanImage,
    SearchBlock,
  },
  data() {
    return {
      value: "",
      placeholder: "搜教室、名称、楼层",
      searchVal: "",
      checked: false,
      dataList: [],
      loading: false,
      finished: false,
      showSearch: false,
      chooseIndex: 0,
      currentIndex: 0,
      swiperOptions: {
        slidesPerView: 3.1,
        slidesPerGroup: 3,
        spaceBetween: 0,
        observer: true,
      },
      active: 0,
      type: 0,
      showVagueSearch: false,
      dataArr: [
        {
          icon: "icon-fangjian_all",
          title: "全部",
          url: "/SmartClassroom/Equipement/Index",
        },
        {
          icon: "icon-control_deng",
          title: "智能照明灯",
          url: "/SmartClassroom/Equipement/Index",
        },

        {
          icon: "icon-control04_kongtiao",
          title: "空调",
          url: "/SmartClassroom/Equipement/AirCondition",
        },
        {
          icon: "icon-control04_chuanglian",
          title: "窗帘",
          url: "/SmartClassroom/Equipement/Curtains",
        },
        {
          icon: "icon-control04_door1",
          title: "自动门",
          url: "/SmartClassroom/Equipement/Lock",
        },
        {
          icon: "icon-control04_jianceyi",
          title: "室内检测仪",
          url: "/SmartClassroom/Equipement/Monitor",
        },
        {
          icon: "icon-control02_yinliang",
          title: "广播",
          url: "/SmartClassroom/Equipement/Monitor",
        },
      ],
      list: [
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
        {
          title: "L1-10056智能灯掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/灯泡.svg"),
          state: 0,
          time: "今天 12:30",
        },
        {
          title: "L1-10057窗帘坏掉了",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/窗帘.svg"),
          state: 0,
          time: "昨天 10:22",
        },
        {
          title: "L1-10058空调掉线",
          local: "三年级2班｜五号教学楼三楼",
          img: require("@/assets/空调.svg"),
          state: 1,
          time: "2021/01/02 09:30",
        },
      ],
    };
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    setData() {},
    onLoad() {},
    onSearch() {},
    handleSwiperReadied() {},
    handleClickSlide() {},
    toggleEquipment(i) {
      this.currentIndex = i;
      document.title = this.dataArr[i].title;
      this.$router.replace({
        query: Object.assign(this.$route.query, { index: i }),
      });
      console.log(this.$route, Object.assign(this.$route.query, { index: i }));
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.index >= 3) {
      this.swiper.slideTo(3, 0, false);
      this.currentIndex = parseInt(this.$route.query.index);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.filter {
  width: 100%;
  height: 100%;
  background-color: $bgColor;
  .v-search-header {
    height: 56px;
    padding: 0 24px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f4f5;
    > span:first-child {
      font-size: 17px;
      font-weight: 400;
      color: $titleColor;
    }
    > span:last-child {
      color: $gray;
      font-size: 22px;
    }
  }
  .search-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    padding-top: 10px;
    margin-bottom: 16px;
    > div:first-child {
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      margin-left: 8px;
      color: $titleColor;
      > i {
        font-size: 20px;
      }
    }
    > div:last-child {
      flex: 1;
      .van-search {
        padding: 0;
      }
    }
  }
  .list {
    box-sizing: border-box;
    padding-top: 14px;
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;
    .van-grid-item__content {
      border-radius: 5px;
      box-shadow: 0px 0px 10px #ebebeb;
    }
    .e-list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      border-radius: 5px;
      margin: 0 12px;
      padding: 12px;
      margin-top: 15px;
      > div:first-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        > div:first-child {
          margin-right: 10px;
        }
        > div:last-child {
          > div:first-child {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 400;
            color: $titleColor;
            white-space: nowrap;
          }
          > div:last-child {
            font-size: 12px;
            font-weight: 400;
            color: $subTitleColor;
            white-space: nowrap;
          }
        }
      }
      > div:last-child {
        text-align: right;
        > div:first-child {
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #a0a0a0;
          white-space: nowrap;
          width: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        > div:last-child {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .popup-list {
    height: 500px;
    overflow-y: scroll;
    > div {
      height: 45px;
      line-height: 45px;
      color: #555;
      padding-left: 24px;
      font-size: 13px;
      font-weight: 400;
      color: #555555;

      i {
        display: none;
        margin-right: 5px;
        color: $themeColor;
      }
    }

    > div.active {
      color: $titleColor;
      i {
        display: inline-block;
      }
    }
  }
  .list-item {
    width: 100%;
    height: 100%;
    position: relative;
    .l-sign {
      position: absolute;
      top: -16px;
      right: -10px;
      color: $themeColor;
      opacity: 0.1;
      > i {
        font-size: 60px;
      }
    }
    .l-title {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      color: #2a2a2a;
      margin-bottom: 6px;
    }
    .l-subtitle {
      font-size: 12px;
      font-weight: 400;
      color: #949494;
      margin-bottom: 18px;
    }
    .l-count {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
        .count {
          > span {
            display: inline-block;
            font-size: 12px;
            transform: scale(0.95);
            font-weight: 400;
            color: $titleColor;
          }
          > span:first-child {
            font-size: 18px;
            font-weight: 500;
            color: $themeColor;
            transform: unset;
          }
        }
        .close {
          font-size: 11px;
          font-weight: 500;
          color: #949494;
        }
      }
    }
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-toggle-page {
    margin-left: 16px;
  }
  .toggle-equipment.active {
    background: $themeColor;
    color: #fff;
  }
  .toggle-equipment {
    width: 107px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #2a2a2a;
    background: #fff;
    i {
      margin-right: 7px;
    }
  }
}
</style>
<style lang="scss">
@import "../../../style/public.scss";
.filter {
  .van-search__content {
    background-color: #fff;
    border-radius: 8px;
  }
  .van-popup {
    overflow: hidden;
  }
  .van-cell,
  .van-cell__value--alone {
    color: $gray;
  }
  .van-tabs {
    height: 100%;
    .van-tabs__nav {
      background-color: transparent;
    }
    .van-tabs__wrap {
      height: 34px;
      .van-tab {
        border-radius: 10px 10px 0px 0px;
        flex: inherit;
        padding: 0 20px;
      }
    }
    .van-tabs__content {
      height: calc(100% - 34px);
      ._container,
      .scroller {
        height: 100%;
      }
    }
    .van-tabs__line {
      background: $themeColor;
    }
  }
}
</style>